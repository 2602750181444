import { Validators } from '@angular/forms';
export const Constants = {
  BVClient: 282241,
  defaultStatus: {
    color: '#808080',
    icon: 'fas fa-adjust',
    isActive: 1,
    type: 'single'
  },

  fixedStatusMap: {
    confirmed: { class: 'text-success', icon: 'fas fa-check-circle', label: 'Bestätigt', color: '#24b314' },
    arrived: { class: 'text-arrived', icon: 'fas fa-store-alt fa-fw', label: 'Angekommen', color: '#ebb12b' },
    placed: { class: 'text-info', icon: 'fas fa-chair fa-fw', label: 'Platziert', color: '#1371fe' },
    finished: { class: 'text-finished', icon: 'fas fa-check-double fa-fw', label: 'Fertig', color: '#495057' },
    pending: { class: 'text-warning', icon: 'fas fa-minus-circle fa-fw', label: 'Ausstehend', color: '#9f9f9f' },
    canceled: { class: 'text-danger', icon: 'fas fa-ban fa-fw', label: 'Storniert', color: '#e65252' },
    noShow: { class: 'text-noshow', icon: 'fas fa-eye-slash fa-fw', label: 'No-Show', color: '#912f5e' },
    waiting: { class: 'text-muted', icon: 'fas fa-hourglass-half fa-fw', label: 'Warteliste', color: '#6c757d' },
    blocked: { class: 'text-muted', icon: 'fas fa-ban fa-fw', label: 'Ausstehende Zahlung', color: '#6c757d' }
  } as { [key: string]: StatusDetails },

  JensMemberID: 3,
  maxReservationUntilDays: 1095, // max 3yrs reservation can be created
  googleAccountClientId: '375315807701-1eoo3dj2k5thdc9rq91u2lusru90nvbt.apps.googleusercontent.com',
  scopeReviewAPI: 'https://www.googleapis.com/auth/business.manage',
  hotelIntegrations: {
    Oracle: 'Oracle',
    ASA: 'ASA',
    ibelsa: 'ibelsa',
    Casablanca: 'Casablanca'
  },

  mealEndTimes: {
    breakfast: 11, // Breakfast before 11 AM
    lunchStart: 11, // Lunch starts at 11 AM
    lunchEnd: 16, // Lunch before 4 PM (16:00)
    dinner: 16 // Dinner starts after 4 PM (16:00)
  },

  endTimeCurrentDay: '06:00'
};

export const HOTEL_SYSTEM_CONFIG: { [system: string]: any[] } = {
  Oracle: [
    { key: 'CLIENT_ID', label: 'Client ID' },
    { key: 'CLIENT_SECRET', label: 'Client Secret' },
    { key: 'AppKey', label: 'App Key' },
    { key: 'HostName', label: 'Host Name' },
    { key: 'Username', label: 'Username' },
    { key: 'Password', label: 'Password' },
    { key: 'HotelId', label: 'Hotel ID' }
  ],
  ASA: [
    { key: 'WSDL_URL', label: 'WSDL URL' },
    { key: 'username', label: 'Username' },
    { key: 'password', label: 'Password' }
  ],
  ibelsa: [{ key: 'IBELSA_KEY', label: 'Ibelsa Key' }],
  Casablanca: [
    { key: 'TENANT_ID', label: 'Tenant ID' },
    { key: 'BEARER_TOKEN', label: 'Bearer Token' },
    { key: 'API_KEY', label: 'API Key' }
  ]
};

export interface StatusDetails {
  class: string;
  icon: string;
  label: string;
  color: string;
}
export const resStatus = [
  {
    label: 'Aktive Reservierungen',
    value: 'active',
    icon: '',
    color: '',
    type: 'group',
    description: 'Angekommen, Platziert & Bestätigt'
  },
  {
    label: 'Bestätigt',
    value: 'confirmed',
    icon: 'fas fa-check-circle',
    color: '#28a745',
    type: 'single'
  },
  {
    label: 'Storniert',
    value: 'canceled',
    icon: 'fas fa-ban fa-fw',
    color: '#dc3545',
    type: 'single'
  },
  {
    label: 'No-Show',
    value: 'noShow',
    icon: 'fas fa-eye-slash fa-fw',
    color: '#912f5e',
    type: 'single'
  },
  {
    label: 'Angekommen',
    value: 'arrived',
    icon: 'fas fa-store-alt fa-fw',
    color: '#ebb12b',
    type: 'single'
  },
  {
    label: 'Platziert',
    value: 'placed',
    icon: 'fas fa-chair fa-fw',
    color: '#17a2b8',
    type: 'single'
  },
  {
    label: 'Ausstehend',
    value: 'pending',
    icon: 'fas fa-minus-circle fa-fw',
    color: '#ffc107',
    type: 'single'
  },
  {
    label: 'Fertig',
    value: 'finished',
    icon: 'fas fa-check-double fa-fw',
    color: '#495057',
    type: 'single'
  },
  {
    label: 'Warteliste',
    value: 'waiting',
    icon: 'fas fa-hourglass-half fa-fw',
    color: '#6c757d',
    type: 'single'
  },
  {
    label: 'Ausstehende Zahlung',
    value: 'blocked',
    icon: 'fas fa-ban fa-fw',
    color: '#6c757d',
    type: 'single'
  }
];
