var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit, OnDestroy } from '@angular/core';
import { Validators, FormBuilder, FormControl } from '@angular/forms';
import format from 'date-fns/format';
import { ReservationService } from '../reservation.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService, Constants } from '@app/core';
import { ConfirmModalComponent } from '@app/shared/confirm-modal/confirm-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { PMSIntegrationService } from './pms-integration.service';
import { Subject } from 'rxjs';
import { ClientService } from '@app/core/client.service';
import { ReplaceGuestReservationComponent } from './replace-guest-reservation/replace-guest-reservation.component';
import { addDays, subDays } from 'date-fns';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
export class HotelReservationComponent {
    constructor(formBuilder, reservationService, snackBar, authService, modalService, pmsIntegrationService, clientService, translate, route) {
        this.formBuilder = formBuilder;
        this.reservationService = reservationService;
        this.snackBar = snackBar;
        this.authService = authService;
        this.modalService = modalService;
        this.pmsIntegrationService = pmsIntegrationService;
        this.clientService = clientService;
        this.translate = translate;
        this.route = route;
        this.subscriptions = [];
        this.isLoadingAutocomplete = false;
        // filteredGuests: any[] = [];
        this.restaurants = [];
        this.times = [];
        this.incompatibilities = [];
        this.areAllTablesFreeAtSelectedTime = true;
        this.stayTimes = [
            // { name: `30 ${this.translate.instant('HotelReservations.Minutes')}`, value: 30 },
            { name: `1 ${this.translate.instant('HotelReservations.Hour')}`, value: 60 },
            { name: `1.5  ${this.translate.instant('HotelReservations.Hours')}`, value: 90 },
            { name: `2  ${this.translate.instant('HotelReservations.Hours')}`, value: 120 },
            { name: `2.5  ${this.translate.instant('HotelReservations.Hours')}`, value: 150 },
            { name: `4  ${this.translate.instant('HotelReservations.Hours')}`, value: 240 }
        ];
        this.ngUnsubscribe = new Subject();
        this.hotelReservations = [];
        this.tableLimit = 25;
        this.copyAllItems = false;
        this.mealTypeMap = {
            breakfast: this.translate.instant('HotelReservations.Breakfast'),
            lunch: this.translate.instant('HotelReservations.Lunch'),
            dinner: this.translate.instant('HotelReservations.Dinner')
        };
        this.breakfastCounter = 1;
        this.lunchCounter = 1;
        this.dinnerCounter = 1;
        this.canceledReservations = [];
        this.mealOptions = [
            { value: 'FR', label: this.translate.instant('HotelReservations.Breakfast'), color: 'lightsalmon' },
            { value: 'HP', label: this.translate.instant('HotelReservations.HalfBoard'), color: 'lightpink' },
            { value: 'VP', label: this.translate.instant('HotelReservations.FullBoard'), color: 'lightblue' }
        ];
        this.mealTypeOrder = {
            dinner: 1,
            lunch: 2,
            breakfast: 3
        };
        this.allHotelReservations = [];
        this.dates = {
            from: '',
            to: ''
        };
        this.showErrorMessage = false;
        this.pmsIntegrations = [];
        this.multipleIntegration = false;
        this.loadingHotelRes = false;
        this.callPlaced = false;
        this.pageSize = 10000;
        this.currentPage = 1;
        this.totalPages = 1;
        this.paginatedReservations = [];
        this.searchTerm = '';
        this.translate.onLangChange.subscribe((event) => { });
    }
    ngOnInit() {
        this.setDates('thisWeek');
        this.initForm();
        this.getTimes();
        // this.subscriptions.push(
        //   this.authService.getActiveClient().subscribe(data => {
        //     this.clientId = data.id;
        //   })
        // );
        // this.minArrivalDate = new Date();
        // this.minDepartureDate = new Date();
        // Fetch all required data before executing route logic
        this.subscriptions.push(forkJoin({
            rooms: this.reservationService.getRooms(),
            incompatibilities: this.reservationService.getIncompatibilities(),
            tags: this.reservationService.getTags()
        }).subscribe(({ rooms, incompatibilities, tags }) => {
            // Process results
            this.restaurants = rooms.filter((room) => room.isActive == 1);
            this.incompatibilities = incompatibilities.data;
            this.tags = tags;
            // Check and execute `placeGuest` only after all API calls complete
            const hotelId = this.route.snapshot.paramMap.get('hotelId');
            if (hotelId) {
                this.placeGuest({ hotelId });
            }
        }));
        this.subscriptions.push(this.reservationService.getPMSDetails().subscribe(res => {
            if (res && res.length) {
                this.pmsIntegrations = [
                    {
                        label: Constants.hotelIntegrations.Oracle,
                        showBtn: !!res.find((item) => item.hotelSystem === Constants.hotelIntegrations.Oracle),
                        onClick: () => this.getHotelReservationOracle()
                    },
                    {
                        label: Constants.hotelIntegrations.ASA,
                        showBtn: !!res.find((item) => item.hotelSystem === Constants.hotelIntegrations.ASA),
                        onClick: () => this.getHotelReservationASA()
                    },
                    {
                        label: Constants.hotelIntegrations.ibelsa,
                        showBtn: !!res.find((item) => item.hotelSystem === Constants.hotelIntegrations.ibelsa),
                        onClick: () => this.getHotelReservationIbelsa()
                    },
                    {
                        label: Constants.hotelIntegrations.Casablanca,
                        showBtn: !!res.find((item) => item.hotelSystem === Constants.hotelIntegrations.Casablanca),
                        onClick: () => this.getHotelReservationCasablanca()
                    }
                ].filter(integration => integration.showBtn); // Remove integrations that are not available
                // Check if there are multiple integrations
                this.multipleIntegration = this.pmsIntegrations.length > 1;
            }
            else {
                this.pmsIntegrations = [];
                this.multipleIntegration = false;
            }
        }, err => {
            this.pmsIntegrations = [];
            this.multipleIntegration = false;
        }));
        this.subscriptions.push(this.reservationService.getAllHotelReservations().subscribe(res => {
            if (res) {
                // this.allHotelReservations = res.map((item: any) => item.hotelReservation.bookingNumber);
                this.allHotelReservations = res;
            }
        }, err => {
            this.allHotelReservations = [];
        }));
    }
    onSelectIntegration(selectedIntegration) {
        this.selectedIntegration = selectedIntegration;
    }
    triggerLoading(index) {
        this.loadingHotelRes = true;
        this.pmsIntegrations[index].onClick();
        this.reservationFormGroup.get('hotelSystem').setValue(this.pmsIntegrations[index].label);
    }
    createNumValues(number) {
        const items = [];
        for (let i = 1; i <= number; i++) {
            items.push(i);
        }
        return items;
    }
    addNewReservationGroup(date, mealType, existingReservation = {}) {
        const group = this.formBuilder.group({
            date: [date, Validators.required],
            time: [existingReservation.time || null, Validators.required],
            listOfTimes: [this.times, Validators.required],
            listOfRestaurants: [this.restaurants, Validators.required],
            restaurant: [existingReservation.restaurant || null, Validators.required],
            table: [null],
            listOfTables: [[]],
            errorMessage: [''],
            isEditable: [false],
            mealType: [mealType, Validators.required],
            resIndex: [],
            tableLoader: false,
            exempt: [false],
            reservationId: [existingReservation.id || '']
        });
        // Watch for changes in `exempt`
        group.get('exempt').valueChanges.subscribe(exempt => {
            if (exempt) {
                group.get('date').clearValidators();
                group.get('time').clearValidators();
                group.get('listOfTimes').clearValidators();
                group.get('listOfRestaurants').clearValidators();
                group.get('restaurant').clearValidators();
                // group.get('table').clearValidators();
                group.get('mealType').clearValidators();
            }
            else {
                group.get('date').setValidators(Validators.required);
                group.get('time').setValidators(Validators.required);
                group.get('listOfTimes').setValidators(Validators.required);
                group.get('listOfRestaurants').setValidators(Validators.required);
                group.get('restaurant').setValidators(Validators.required);
                // group.get('table').setValidators(Validators.required);
                group.get('mealType').setValidators(Validators.required);
            }
            group.get('date').updateValueAndValidity({ emitEvent: false });
            group.get('time').updateValueAndValidity({ emitEvent: false });
            group.get('listOfTimes').updateValueAndValidity({ emitEvent: false });
            group.get('listOfRestaurants').updateValueAndValidity({ emitEvent: false });
            group.get('restaurant').updateValueAndValidity({ emitEvent: false });
            // group.get('table').updateValueAndValidity({ emitEvent: false });
            group.get('mealType').updateValueAndValidity({ emitEvent: false });
        });
        return group;
    }
    addNewReservationFormAtIndex(date, index, mealType = '') {
        this.reservationForm.insert(index, this.addNewReservationGroup(date, mealType));
    }
    deleteReservationFromArray(index) {
        this.reservationForm.removeAt(index);
    }
    createReservation() {
        // if everything is ok create new reservations
        if (this.reservationFormGroup.valid) {
            this.processReservations();
        }
        // check if input form is invalid without reservations form
        if (!this.reservationFormGroup.controls.name.valid ||
            !this.reservationFormGroup.controls.roomNumber.valid ||
            !this.reservationFormGroup.controls.reservationNumber.valid ||
            !this.reservationFormGroup.controls.arrivalDate.valid ||
            !this.reservationFormGroup.controls.departureDate.valid ||
            !this.reservationFormGroup.controls.stayTime.valid) {
            this.snackBar.open(this.translate.instant('HotelReservations.CheckYourDetails'), '', {
                duration: 2000,
                panelClass: ['snackbar-error']
            });
            return;
        }
        // if all input fields are valid except list of reservations show this confirm dialog
        if (!this.reservationFormGroup.controls.reservations.valid) {
            // Loop through all form controls and log the invalid ones
            // for (const controlName in this.reservationFormGroup.controls) {
            //   const control = this.reservationFormGroup.controls[controlName];
            //   if (control.invalid) {
            //     console.log(`❌ ${controlName} is invalid`);
            //     console.log(`   ➡ Current Value:`, control.value);
            //     // Check and log specific validation errors
            //     if (control.errors) {
            //       Object.keys(control.errors).forEach(errorKey => {
            //         console.log(`   🚨 Error: ${errorKey} -`, control.errors[errorKey]);
            //       });
            //     }
            //     // If it's a FormArray (like reservations), check its children
            //     if (control instanceof FormArray) {
            //       console.log(`   🔎 Inspecting FormArray: ${controlName}`);
            //       control.controls.forEach((group, index) => {
            //         if (group.invalid) {
            //           console.log(`   ❌ reservations[${index}] is invalid`);
            //           console.log(`      ➡ Current Value:`, group.value);
            //           if (group.errors) {
            //             Object.keys(group.errors).forEach(errorKey => {
            //               console.log(`      🚨 Error: ${errorKey} -`, group.errors[errorKey]);
            //             });
            //           }
            //           // Check each FormGroup inside the FormArray
            //           if (group instanceof FormGroup) {
            //             Object.keys(group.controls).forEach(childName => {
            //               const childControl = group.get(childName);
            //               if (childControl && childControl.invalid) {
            //                 console.log(`      ❌ Field '${childName}' inside reservations[${index}] is invalid`);
            //                 console.log(`         ➡ Current Value:`, childControl.value);
            //                 if (childControl.errors) {
            //                   Object.keys(childControl.errors).forEach(errorKey => {
            //                     console.log(`         🚨 Error: ${errorKey} -`, childControl.errors[errorKey]);
            //                   });
            //                 }
            //               }
            //             });
            //           }
            //         }
            //       });
            //     }
            //   } else {
            //     console.log(`✅ ${controlName} is valid`);
            //   }
            // }
            const modalRef = this.modalService.open(ConfirmModalComponent);
            modalRef.componentInstance.title = this.translate.instant('HotelReservations.NotAllReservationsFulfilled');
            modalRef.componentInstance.message = this.translate.instant('HotelReservations.DaysWithoutReservation');
            modalRef.componentInstance.showInfo = false;
            modalRef.componentInstance.buttonText = this.translate.instant('HotelReservations.Yes');
            modalRef.result.then(result => {
                if (result === 'ok') {
                    this.processReservations();
                }
            }, () => { });
        }
    }
    searchFreeTables(index, previousTables = []) {
        const selectedRestaurantId = this.reservationForm.value[index].restaurant;
        const time = this.reservationForm.value[index].time;
        if (selectedRestaurantId && time) {
            this.updateFormGroupValue('reservations', { tableLoader: true }, index);
            if (this.reservationForm.value[index].table) {
                this.reservationForm.controls[index].get('table').patchValue(null, { emitEvent: false });
                this.updateFormGroupValue('reservations', { listOfTables: [] }, index);
                this.updateFormGroupValue('reservations', { errorMessage: '' }, index);
            }
            const data = this.reservationFormGroup.value;
            const res = this.reservationForm.value[index];
            this.subscriptions.push(this.reservationService
                .checkFreeTables(res.date, time, data.numberOfPersons, data.stayTime.value, true, true, res.reservationId || '', selectedRestaurantId)
                .subscribe(response => {
                const selectedRestaurant = response[0];
                this.fillTableArrayWithAvailableTables(selectedRestaurant, index, previousTables);
            }));
        }
    }
    fillTableArrayWithAvailableTables(selectedRestaurant, index, previousTables) {
        const tables = [];
        const onlyTables = [];
        if (selectedRestaurant && selectedRestaurant.tables) {
            for (let i = 0; i < selectedRestaurant.tables.length; i++) {
                const element = selectedRestaurant.tables[i];
                if (!element.isRoom) {
                    let isNotAvailable = false;
                    let msg = this.translate.instant('HotelReservations.TableIsFree');
                    if (element.isFree === false) {
                        isNotAvailable = true;
                        msg = this.translate.instant('HotelReservations.TableIsOccupied');
                    }
                    else if (parseInt(this.reservationFormGroup.get('numberOfPersons').value) > element.seats) {
                        isNotAvailable = true;
                        msg = `Max ${element.seats} ${this.translate.instant('HotelReservations.Persons')}`;
                    }
                    if (!element.isCombined) {
                        tables.push({ table: element, isNotAvailable, msg });
                        onlyTables.push(element);
                    }
                }
            }
        }
        this.updateFormGroupValue('reservations', { listOfTables: tables }, index);
        if (previousTables && previousTables.length > 0 && tables.length > 0) {
            const availableTables = onlyTables.filter((element) => element.isFree && parseInt(this.reservationFormGroup.get('numberOfPersons').value) <= element.seats);
            // Check if all previously selected tables exist in available tables
            const allTablesExist = previousTables.every((tableId) => availableTables.some((item) => item.id === tableId));
            if (!allTablesExist) {
                this.updateFormGroupValue('reservations', { errorMessage: `${this.translate.instant('HotelReservations.TablesNotFree')}` }, index);
            }
            else {
                setTimeout(() => {
                    this.updateFormGroupValue('reservations', { table: previousTables }, index);
                }, 1000); // 1 seconds delay
                this.updateFormGroupValue('reservations', { errorMessage: '' }, index);
            }
        }
        this.updateFormGroupValue('reservations', { tableLoader: false }, index);
    }
    onNumberOfPersonsChange() {
        for (let i = 0; i < this.reservationForm.value.length; i++) {
            const element = this.reservationForm.value[i];
            if (element.restaurant) {
                this.searchFreeTables(i);
            }
        }
    }
    updateFormGroupValue(formGroupName, value, index) {
        this.reservationFormGroup.controls[formGroupName].at(index).patchValue(value, { emitEvent: false });
    }
    processReservations() {
        const reservationFormData = this.reservationFormGroup.value;
        const reservationArray = reservationFormData.reservations;
        const reservations = [];
        for (let i = 0; i < reservationArray.length; i++) {
            const tempObject = {};
            const element = reservationArray[i];
            // if (element.date && element.restaurant && element.table) {
            if (element.date && element.restaurant && element.time) {
                tempObject.reservedFor = format(element.date, 'YYYY-MM-DD') + ' ' + element.time + ':00:00';
                tempObject.room = element.restaurant;
                tempObject.table = element.table;
                tempObject.reservationId = element.reservationId;
                reservations.push(tempObject);
            }
        }
        if (reservations.length === 0) {
            this.snackBar.open(`${this.translate.instant('HotelReservations.MinOneReservation')}`, '', {
                duration: 2000,
                panelClass: ['snackbar-error']
            });
            return;
        }
        const reservation = {
            name: reservationFormData.name,
            roomNumber: reservationFormData.roomNumber,
            reservationNumber: reservationFormData.reservationNumber,
            arrivalDate: reservationFormData.arrivalDate,
            departureDate: reservationFormData.departureDate,
            selectedTags: reservationFormData.selectedTags,
            numberOfPersons: reservationFormData.numberOfPersons,
            numberOfKids: reservationFormData.numberOfKids,
            numberOfHighChairs: reservationFormData.numberOfHighChairs,
            incompatibilities: reservationFormData.incompatibilities,
            notes: reservationFormData.notes,
            reservations: reservations,
            stayTime: reservationFormData.stayTime.value,
            firstName: reservationFormData.firstName,
            email: reservationFormData.email,
            phone: reservationFormData.phone,
            board: reservationFormData.board,
            groupTags: reservationFormData.groupTags,
            hotelSystem: reservationFormData.hotelSystem || null,
            guestNotes: reservationFormData.guestNotes || null,
            bookingId: reservationFormData.bookingId || null,
            hotelId: reservationFormData.hotelId,
            guestId: reservationFormData.guestId,
            canceledReservations: this.canceledReservations,
            createdAt: new Date()
        };
        this.submitObservable = this.subscriptions.push(this.reservationService.addMultipleReservations(reservation).subscribe((res) => {
            if (res) {
                this.callPlaced = false;
                this.snackBar.open(`${this.translate.instant('HotelReservations.BookingSuccess')}`, '', {
                    duration: 3000,
                    panelClass: ['snackbar-success']
                });
                const foundPlaceRes = this.hotelReservations.find((item) => item.reservationNo.toString() === reservationFormData.reservationNumber.toString());
                if (foundPlaceRes) {
                    foundPlaceRes.hotelId = res.id;
                    this.hotelReservations = [...this.hotelReservations];
                    this.setHotelPaginationData();
                    this.initForm();
                }
                else {
                    console.warn(`Reservation with number ${reservationFormData.reservationNumber} not found.`);
                }
            }
        }, err => {
            if (err.status === 422) {
                this.snackBar.open(err.error.msg, '', {
                    duration: 3000,
                    panelClass: ['snackbar-error']
                });
            }
        }));
    }
    initForm() {
        this.reservationFormGroup = this.formBuilder.group({
            name: [null, Validators.required],
            roomNumber: [null, Validators.required],
            reservationNumber: ['', Validators.required],
            arrivalDate: [null, Validators.required],
            departureDate: [null, Validators.required],
            stayTime: new FormControl(null, Validators.required),
            selectedTags: [[]],
            numberOfPersons: [1, Validators.required],
            numberOfKids: [0, Validators.required],
            numberOfHighChairs: [0, Validators.required],
            incompatibilities: [null],
            notes: [null],
            reservations: this.formBuilder.array([]),
            board: [null, Validators.required],
            firstName: [null],
            email: [null],
            phone: [null],
            groupTags: [null],
            hotelSystem: [null],
            guestNotes: [null],
            bookingId: [null],
            hotelId: [null],
            guestId: [null]
        });
        setTimeout(() => {
            this.reservationFormGroup.controls['stayTime'].setValue(this.stayTimes[0]);
        }, 500);
    }
    get reservationForm() {
        return this.reservationFormGroup.get('reservations');
    }
    ngOnDestroy() {
        this.subscriptions.forEach(subscription => {
            subscription.unsubscribe();
        });
    }
    // Function to validate To and From date
    validateDateRange() {
        this.showErrorMessage = false;
        if (moment(this.dates.from).isAfter(this.dates.to)) {
            this.showErrorMessage = true;
        }
    }
    setDates(type) {
        if (type === 'thisWeek') {
            const date = moment();
            this.dates.from = date.startOf('week');
            this.dates.to = moment(this.dates.from).endOf('week');
        }
        else if (type === 'nextWeek') {
            const date = moment().add(1, 'week');
            this.dates.from = date.startOf('week');
            this.dates.to = moment(this.dates.from).endOf('week');
        }
        else if (type === 'next2Weeks') {
            const date = moment().add(1, 'week');
            this.dates.from = date.startOf('week');
            this.dates.to = moment(this.dates.from)
                .add(1, 'week')
                .endOf('week');
        }
        else if (type === 'nextMonth') {
            const date = moment().add(1, 'month');
            this.dates.from = date.startOf('month');
            this.dates.to = moment(this.dates.from).endOf('month');
        }
        else if (type === 'next2Months') {
            const date = moment().add(1, 'month');
            this.dates.from = date.startOf('month');
            this.dates.to = moment(this.dates.from)
                .add(1, 'month')
                .endOf('month');
        }
        else if (type === 'next6Months') {
            const date = moment().add(1, 'month');
            this.dates.from = date.startOf('month');
            this.dates.to = moment(this.dates.from)
                .add(5, 'months')
                .endOf('month');
        }
    }
    getHotelReservationOracle() {
        this.fetchingStartedHotelRes();
        this.subscriptions.push(this.pmsIntegrationService.getHotelReservationsOracle(this.dates.from, this.dates.to).subscribe(res => {
            if (res) {
                console.log('Oracle1', this.hotelReservations);
                this.hotelReservations = res.map((item) => {
                    let packagesCode = '';
                    if (item.packages && item.packages[0]) {
                        packagesCode = item.packages && item.packages[0] ? item.packages[0].packageCode : '';
                        console.log('packages', packagesCode, item.packages[0].packageHeaderType.primaryDetails.description, item.packages);
                    }
                    let hotelReservation = {};
                    hotelReservation.reservationNo = item.reservationIdList[0].id;
                    hotelReservation.arrivalDate = item.roomStay.arrivalDate;
                    hotelReservation.departureDate = item.roomStay.departureDate;
                    hotelReservation.guestCount = item.roomStay.adultCount + item.roomStay.childCount;
                    // hotelReservation.guestCount = item.roomStay.adultCount;
                    hotelReservation.childCount = item.roomStay.childCount;
                    hotelReservation.roomNo = item.roomStay.roomId;
                    hotelReservation.guestInfo = {
                        name: item.reservationGuest.surname,
                        firstName: item.reservationGuest.givenName,
                        email: item.reservationGuest.email,
                        phoneNo: item.reservationGuest.phoneNumber,
                        guestNotes: ''
                    };
                    // hotelReservation.board = packagesCode;
                    const foundReservation = this.allHotelReservations.find((res) => res.hotelReservation.bookingNumber.toString() === hotelReservation.reservationNo.toString());
                    hotelReservation.hotelId = foundReservation ? foundReservation.hotelReservation.id : null;
                    return hotelReservation;
                });
            }
            console.log('Oracle2', this.hotelReservations);
            this.setHotelPaginationData();
        }, err => {
            this.setHotelPaginationData();
            const errMsg = err && err.error.msg ? err.error.msg : `${this.translate.instant('HotelReservations.SomethingWentWrong')}`;
            this.snackBar.open(errMsg, '', {
                duration: 2000,
                panelClass: ['snackbar-error']
            });
        }));
    }
    getHotelReservationASA() {
        this.fetchingStartedHotelRes();
        this.subscriptions.push(this.pmsIntegrationService.getHotelReservationsASA(this.dates.from, this.dates.to).subscribe(res => {
            if (res) {
                console.log('ASA1', res);
                for (let item of res) {
                    if (!item.type || item.type != 'contingent') {
                        let hotelReservation = {};
                        hotelReservation.reservationNo = item.number;
                        const roomNumbers = item.roomReservations.roomReservation.map((reservation) => reservation.room.number);
                        const roomNumbersString = roomNumbers.join(',');
                        const roomReservations = item.roomReservations.roomReservation[0];
                        const boardCode = roomReservations.boardCode;
                        hotelReservation.arrivalDate = roomReservations.arrival;
                        hotelReservation.departureDate = roomReservations.departure;
                        hotelReservation.guestCount =
                            (roomReservations.adults ? roomReservations.adults : 0) +
                                (roomReservations.children ? roomReservations.children : 0);
                        hotelReservation.childCount = roomReservations.children ? roomReservations.children : 0;
                        hotelReservation.roomNo = roomNumbersString;
                        const annotations = [
                            roomReservations.diningRoomAnnotation,
                            roomReservations.kitchenAnnotation,
                            roomReservations.diningAnnotation
                        ]
                            .filter(Boolean)
                            .join('\n');
                        if (annotations) {
                            hotelReservation.notes = annotations;
                        }
                        const guestName = roomReservations.guestName ? roomReservations.guestName.split(' ') : '';
                        let guestAnnotations = '';
                        if (roomReservations.roomGuests &&
                            roomReservations.roomGuests.roomGuest &&
                            roomReservations.roomGuests.roomGuest.length > 0) {
                            guestAnnotations = roomReservations.roomGuests.roomGuest
                                .map((guest) => [guest.diningRoomAnnotation, guest.kitchenAnnotation, guest.diningAnnotation])
                                .flat()
                                .filter(Boolean)
                                .join('\n');
                        }
                        hotelReservation.guestInfo = {
                            name: item.guest
                                ? item.guest.lastName
                                : guestName
                                    ? guestName[0]
                                    : `${this.translate.instant('HotelReservations.NoName')}`,
                            firstName: item.guest ? item.guest.firstName : guestName ? guestName[1] : '',
                            email: item.guest ? item.guest.email : '',
                            phoneNo: item.guest ? item.guest.telephone : '',
                            guestNotes: guestAnnotations
                        };
                        if (boardCode == 'BB' || boardCode == 'HB' || boardCode == 'FB') {
                            hotelReservation.board = this.replaceBoardcode(boardCode);
                        }
                        hotelReservation.reservationInfo = {
                            BoardCode: boardCode ? boardCode : '',
                            RatePlanCode: roomReservations.ratePlanCode ? roomReservations.ratePlanCode : ''
                        };
                        const foundReservation = this.allHotelReservations.find((res) => res.hotelReservation.bookingNumber.toString() === hotelReservation.reservationNo.toString());
                        hotelReservation.hotelId = foundReservation ? foundReservation.hotelReservation.id : null;
                        hotelReservation.bookingId = item.id;
                        this.hotelReservations.push(hotelReservation);
                    }
                }
            }
            this.hotelReservations = [...this.hotelReservations];
            console.log('ASA2', this.hotelReservations);
            this.setHotelPaginationData();
        }, err => {
            this.setHotelPaginationData();
            const errMsg = err && err.error.msg ? err.error.msg : `${this.translate.instant('HotelReservations.SomethingWentWrong')}`;
            this.snackBar.open(errMsg, '', {
                duration: 2000,
                panelClass: ['snackbar-error']
            });
        }));
    }
    getHotelReservationIbelsa() {
        this.fetchingStartedHotelRes();
        this.subscriptions.push(this.pmsIntegrationService.getHotelReservationIbelsa(this.dates.from, this.dates.to).subscribe(res => {
            if (res) {
                console.log('Ibelsa1', res);
                for (let item of res) {
                    if (item.room_stays && item.room_stays.length) {
                        let hotelReservation = {};
                        hotelReservation.reservationNo = item.id;
                        const roomNumbers = item.room_stays.map((room) => room.room_name);
                        const roomNumbersString = roomNumbers.join(',');
                        hotelReservation.arrivalDate = item.starts_at;
                        hotelReservation.departureDate = item.ends_at;
                        hotelReservation.guestCount = item.person_count;
                        hotelReservation.roomNo = roomNumbersString;
                        hotelReservation.notes = item.invoice_items && item.invoice_items[0] ? item.invoice_items[0].name : '';
                        hotelReservation.guestInfo = {
                            name: item.guest ? item.guest.lastname : `${this.translate.instant('HotelReservations.NoName')}`,
                            firstName: item.guest ? item.guest.firstname : '',
                            email: item.guest ? item.guest.email : '',
                            phoneNo: item.guest ? item.guest.mobilephone : '',
                            guestNotes: ''
                        };
                        // hotelReservation.board = boardCode;
                        const foundReservation = this.allHotelReservations.find((res) => res.hotelReservation.bookingNumber.toString() === hotelReservation.reservationNo.toString());
                        hotelReservation.hotelId = foundReservation ? foundReservation.hotelReservation.id : null;
                        this.hotelReservations.push(hotelReservation);
                    }
                }
            }
            this.hotelReservations = [...this.hotelReservations];
            console.log('Ibelsa2', this.hotelReservations);
            this.setHotelPaginationData();
        }, err => {
            this.setHotelPaginationData();
            const errMsg = err && err.error.msg ? err.error.msg : `${this.translate.instant('HotelReservations.SomethingWentWrong')}`;
            this.snackBar.open(errMsg, '', {
                duration: 2000,
                panelClass: ['snackbar-error']
            });
        }));
    }
    getHotelReservationCasablanca() {
        this.fetchingStartedHotelRes();
        this.subscriptions.push(this.pmsIntegrationService.getHotelReservationCasablanca(this.dates.from, this.dates.to).subscribe(res => {
            if (res) {
                console.log('Casablanca1', res);
                for (let item of res) {
                    if (item.$.ResStatus === 'Reserved') {
                        let hotelReservation = {};
                        // Extract item number
                        const uniqueID = item.UniqueID.find((id) => id.$.Type === '14');
                        hotelReservation.reservationNo = uniqueID ? uniqueID.$.ID : '';
                        // hotelReservation.reservationNo = item.ResGlobalInfo && item.ResGlobalInfo.HotelReservationIDs ?  item.ResGlobalInfo.HotelReservationIDs.HotelReservationID[0].$.ResID_Type: '';
                        hotelReservation.notes =
                            item.ResGlobalInfo &&
                                item.ResGlobalInfo.Comments &&
                                item.ResGlobalInfo.Comments.Comment &&
                                item.ResGlobalInfo.Comments.Comment.Text
                                ? item.ResGlobalInfo.Comments.Comment.Text.replace(/\r\n/g, '\n')
                                : '';
                        // Extract room numbers
                        const roomStays = Array.isArray(item.RoomStays.RoomStay)
                            ? item.RoomStays.RoomStay
                            : [item.RoomStays.RoomStay];
                        const roomNumbers = roomStays.map((roomStay) => roomStay.RoomTypes.RoomType.$.RoomID);
                        hotelReservation.roomNo = roomNumbers.join(',');
                        // Extract dates
                        const timeSpan = roomStays[0].TimeSpan.$;
                        hotelReservation.arrivalDate = timeSpan.Start;
                        hotelReservation.departureDate = timeSpan.End;
                        // Extract guest count
                        // const guestCount =  roomStays[0].GuestCounts && roomStays[0].GuestCounts.GuestCount && roomStays[0].GuestCounts.GuestCount.$ ? roomStays[0].GuestCounts.GuestCount.$.Count : 0;
                        // hotelReservation.guestCount = guestCount;
                        let guestCount = 0;
                        if (roomStays[0].GuestCounts && roomStays[0].GuestCounts.GuestCount) {
                            const guestData = roomStays[0].GuestCounts.GuestCount;
                            if (Array.isArray(guestData)) {
                                guestCount = guestData.reduce((sum, guest) => sum + (guest.$.Count ? parseInt(guest.$.Count, 10) : 0), 0);
                            }
                            else if (guestData.$ && guestData.$.Count) {
                                guestCount = parseInt(guestData.$.Count, 10);
                            }
                        }
                        hotelReservation.guestCount = guestCount;
                        // Extract guest information
                        if (item.ResGlobalInfo && item.ResGlobalInfo.Profiles) {
                            const guestProfile = item.ResGlobalInfo.Profiles.ProfileInfo.Profile.Customer;
                            hotelReservation.guestInfo = {
                                name: guestProfile.PersonName.Surname || `${this.translate.instant('HotelReservations.NoName')}`,
                                firstName: guestProfile.PersonName.NamePrefix || '',
                                email: guestProfile.Email ? guestProfile.Email._ : '',
                                phoneNo: guestProfile.Telephone && guestProfile.Telephone[0] && guestProfile.Telephone[0].$
                                    ? guestProfile.Telephone[0].$.PhoneNumber
                                    : '',
                                guestNotes: ''
                            };
                        }
                        let ratePlanCode = '';
                        if (roomStays[0].RoomRates && roomStays[0].RoomRates.RoomRate) {
                            const roomRate = roomStays[0].RoomRates.RoomRate;
                            if (Array.isArray(roomRate)) {
                                ratePlanCode = roomRate[0].$.RatePlanCode;
                            }
                            else if (roomRate.$.RatePlanCode) {
                                ratePlanCode = roomRate.$.RatePlanCode;
                            }
                        }
                        hotelReservation.reservationInfo = {
                            RatePlanCode: ratePlanCode ? ratePlanCode : ''
                        };
                        const foundReservation = this.allHotelReservations.find((res) => res.hotelReservation.bookingNumber.toString() === hotelReservation.reservationNo.toString());
                        hotelReservation.hotelId = foundReservation ? foundReservation.hotelReservation.id : null;
                        // Add to reservations array
                        this.hotelReservations.push(hotelReservation);
                    }
                }
            }
            this.hotelReservations = [...this.hotelReservations];
            console.log('Casablanca2', this.hotelReservations);
            this.setHotelPaginationData();
        }, err => {
            this.setHotelPaginationData();
            const errMsg = err && err.error.msg ? err.error.msg : `${this.translate.instant('HotelReservations.SomethingWentWrong')}`;
            this.snackBar.open(errMsg, '', {
                duration: 2000,
                panelClass: ['snackbar-error']
            });
        }));
    }
    placeGuest(reservation) {
        if (reservation.hotelId) {
            this.reservationService.getHotelReservation(reservation.hotelId).subscribe(hotelResData => {
                if (hotelResData && hotelResData.reservation.length && hotelResData.reservation[0]) {
                    const board = hotelResData.board;
                    const selectedStayTime = this.stayTimes.find(st => st.value === hotelResData.reservation[0].stayTime) || null;
                    const selectedTags = [];
                    if ((hotelResData.reservation[0].tags, hotelResData.reservation[0].tags.length)) {
                        hotelResData.reservation[0].tags.map((item) => {
                            selectedTags.push(item.tag);
                        });
                    }
                    const placeReservation = {
                        name: hotelResData.reservation[0].guestData ? hotelResData.reservation[0].guestData.name : '',
                        roomNumber: hotelResData.roomNo,
                        reservationNumber: hotelResData.bookingNumber,
                        arrivalDate: moment(hotelResData.arrivalDate),
                        departureDate: moment(hotelResData.departureDate),
                        selectedTags: selectedTags,
                        numberOfPersons: hotelResData.reservation[0].peopleCount,
                        numberOfKids: hotelResData.reservation[0].children,
                        numberOfHighChairs: hotelResData.reservation[0].highChairs,
                        incompatibilities: hotelResData.reservation[0].guestData &&
                            hotelResData.reservation[0].guestData.intolerance &&
                            hotelResData.reservation[0].guestData.intolerance != 'null'
                            ? JSON.parse(hotelResData.reservation[0].guestData.intolerance)
                            : null,
                        notes: hotelResData.reservation[0].notes,
                        stayTime: selectedStayTime,
                        firstName: hotelResData.reservation[0].guestData ? hotelResData.reservation[0].guestData.firstName : '',
                        email: hotelResData.reservation[0].guestData ? hotelResData.reservation[0].guestData.email : '',
                        phone: hotelResData.reservation[0].guestData ? hotelResData.reservation[0].guestData.phone : '',
                        board: board,
                        groupTags: hotelResData.groupTags,
                        hotelSystem: hotelResData.hotelSystem,
                        guestNotes: hotelResData.reservation[0].guestData ? hotelResData.reservation[0].msg : '',
                        bookingId: hotelResData.bookingId,
                        hotelId: hotelResData.id,
                        guestId: hotelResData.reservation[0].guestData.id
                    };
                    this.setGuestValue(placeReservation);
                    const dinnerReservations = [];
                    const lunchReservations = [];
                    const breakfastReservations = [];
                    const today = new Date();
                    today.setHours(0, 0, 0, 0);
                    let allExistingReservation = [];
                    hotelResData.reservation.forEach((item) => {
                        const existingReservation = {
                            time: new Date(item.reservedFor).toTimeString().slice(0, 5),
                            restaurant: item.roomId,
                            id: item.id,
                            table: item.isTablePlan && item.isTablePlan.length ? item.isTablePlan.split(',').map(Number) : []
                        };
                        allExistingReservation = [...allExistingReservation, existingReservation];
                        const date = new Date(item.reservedFor);
                        date.setHours(0, 0, 0, 0);
                        const reservedTime = new Date(item.reservedFor).getHours();
                        if (date.getTime() >= today.getTime()) {
                            if (reservedTime < Constants.mealEndTimes.breakfast) {
                                breakfastReservations.push(this.addNewReservationGroup(new Date(item.reservedFor), 'breakfast', existingReservation));
                            }
                            else if (reservedTime >= Constants.mealEndTimes.breakfast &&
                                reservedTime < Constants.mealEndTimes.lunchEnd) {
                                lunchReservations.push(this.addNewReservationGroup(new Date(item.reservedFor), 'lunch', existingReservation));
                            }
                            else {
                                dinnerReservations.push(this.addNewReservationGroup(new Date(item.reservedFor), 'dinner', existingReservation));
                            }
                        }
                    });
                    const existingReservationsFormControl = this.reservationForm.controls;
                    const existingReservations = existingReservationsFormControl.map(control => control);
                    const allReservations = [
                        ...existingReservations,
                        ...dinnerReservations,
                        ...lunchReservations,
                        ...breakfastReservations
                    ];
                    this.sortReservationAndFilterReservations(allReservations, allExistingReservation);
                    this.previousArrivalDate = new Date(this.reservationFormGroup.value.arrivalDate);
                    this.previousArrivalDate.setHours(0, 0, 0, 0);
                    this.previousDepartureDate = new Date(this.reservationFormGroup.value.departureDate);
                    this.previousDepartureDate.setHours(0, 0, 0, 0);
                }
            }, err => {
                console.log('No reservation found.');
            });
        }
        else {
            const placeReservation = {
                name: reservation.guestInfo.name,
                roomNumber: reservation.roomNo,
                reservationNumber: reservation.reservationNo,
                arrivalDate: moment(reservation.arrivalDate),
                departureDate: moment(reservation.departureDate),
                numberOfPersons: reservation.guestCount,
                numberOfKids: reservation.childCount ? reservation.childCount : 0,
                board: reservation.board,
                notes: reservation.notes,
                firstName: reservation.guestInfo.firstName,
                email: reservation.guestInfo.email,
                phone: reservation.guestInfo.phone,
                guestNotes: reservation.guestInfo.guestNotes,
                bookingId: reservation.bookingId,
                groupTags: reservation.reservationInfo.RatePlanCode
            };
            this.setGuestValue(placeReservation);
            this.checkIsSelectedTimeIntervalValid();
        }
    }
    setGuestValue(placeReservation) {
        Object.entries(placeReservation).forEach(([key, value]) => {
            if (this.reservationFormGroup.get(key)) {
                this.reservationFormGroup.get(key).setValue(value);
            }
        });
        this.reservationForm.clear();
        this.callPlaced = true;
    }
    callCopyAllItems(i, mealType) {
        this.copyAllItems = true;
        // Find the first row of the given mealType
        // const firstElement = this.reservationForm.value.find((item: any) => item.mealType === mealType);
        // if (!firstElement) return; // If no such row, exit
        const firstElement = this.reservationForm.value[i];
        // Iterate through all rows and update only matching mealType rows
        this.reservationForm.value.forEach((element, index) => {
            if (element.mealType === mealType && element.resIndex && element.resIndex != 1) {
                this.updateFormGroupValue('reservations', { listOfTimes: firstElement.listOfTimes }, index);
                this.updateFormGroupValue('reservations', { listOfRestaurants: firstElement.listOfRestaurants }, index);
                this.updateFormGroupValue('reservations', { listOfTables: firstElement.listOfTables }, index);
                this.updateFormGroupValue('reservations', { time: firstElement.time }, index);
                this.updateFormGroupValue('reservations', { restaurant: firstElement.restaurant }, index);
                (() => __awaiter(this, void 0, void 0, function* () {
                    if (firstElement.table && firstElement.table.length > 0) {
                        this.updateFormGroupValue('reservations', { tableLoader: true }, index);
                        // Check availability for all selected tables
                        const availabilityChecks = yield Promise.all(firstElement.table.map((tableId) => this.checkTableAvailability(firstElement.time, tableId, index)));
                        // If any table is not available, show an error
                        const isAnyTableUnavailable = availabilityChecks.includes(false);
                        if (isAnyTableUnavailable) {
                            this.updateFormGroupValue('reservations', { errorMessage: 'One or more selected tables are not free' }, index);
                        }
                        else {
                            this.updateFormGroupValue('reservations', { errorMessage: '' }, index);
                        }
                        this.updateFormGroupValue('reservations', { table: firstElement.table }, index);
                        this.updateFormGroupValue('reservations', { tableLoader: false }, index);
                    }
                    else {
                        this.updateFormGroupValue('reservations', { table: [] }, index);
                    }
                }))();
            }
        });
    }
    checkTableAvailability(time, table, index) {
        return new Promise(resolve => {
            const selectedRestaurantId = this.reservationForm.value[index].restaurant;
            const data = this.reservationFormGroup.value;
            const res = this.reservationForm.value[index];
            this.subscriptions.push(this.reservationService
                .checkFreeTables(res.date, time, data.numberOfPersons, data.stayTime.value, true, true, res.reservationId || '', selectedRestaurantId)
                .subscribe(response => {
                const selectedRestaurant = response[0];
                const availableTables = selectedRestaurant.tables.filter((element) => element.isFree && parseInt(this.reservationFormGroup.get('numberOfPersons').value) <= element.seats);
                const tableExist = availableTables.some((item) => item.id === table);
                resolve(tableExist);
            }));
        });
    }
    getTimes() {
        this.defaultTime = moment().format('HH:mm');
        this.times = [];
        let tStart = 0;
        const interval = 15;
        for (let i = 0; tStart < 24 * 60; i++) {
            const hh = Math.floor(tStart / 60); // getting hours of day in 0-24 format
            const mm = tStart % 60; // getting minutes of the hour in 0-55 format
            const timeObj = {
                time: ('0' + hh).slice(-2) + ':' + ('0' + mm).slice(-2),
                isClosed: true
            };
            this.times.push(timeObj);
            console.log('get times');
            const hhNext = Math.floor((tStart + interval) / 60); // getting hours of day in 0-24 format
            const mmNext = (tStart + interval) % 60; // getting minutes of the hour in 0-55 format
            const dateObjNext = new Date();
            dateObjNext.setHours(hhNext);
            dateObjNext.setMinutes(mmNext);
            const dateObj = new Date();
            dateObj.setHours(hh);
            dateObj.setMinutes(mm);
            const defaultTimeDateObj = new Date();
            const defaultTimeSplit = this.defaultTime.split(':');
            defaultTimeDateObj.setHours(+defaultTimeSplit[0]);
            defaultTimeDateObj.setMinutes(+defaultTimeSplit[1]);
            if (dateObj < defaultTimeDateObj && dateObjNext > defaultTimeDateObj) {
                const defaultTimeObj = {
                    time: this.defaultTime,
                    isClosed: false
                };
            }
            tStart = tStart + interval;
        }
        // Check opening hours and append Closed label to times which are closed
        this.clientService
            .getOpeningHourOfDay(moment(this.reservationFormGroup.value.date).format('YYYY-MM-DD'))
            .takeUntil(this.ngUnsubscribe)
            .subscribe((openingHours) => __awaiter(this, void 0, void 0, function* () {
            if (openingHours.length) {
                // Loop trough all entries to check time
                yield openingHours.reduce((prev, oh) => prev.then(() => __awaiter(this, void 0, void 0, function* () {
                    this.times = yield this._checkTimesWithOpeningHours(oh.tFrom, oh.tTo, oh.closed);
                })), Promise.resolve());
            }
            else {
                // No opening hours for this day, so its closed
                const newTimes = yield this.times.map((time) => {
                    time.isClosed = true;
                    return time;
                });
                this.times = [...newTimes];
            }
        }));
    }
    _checkTimesWithOpeningHours(tFrom, tTo, closed = false) {
        const from = moment(tFrom, 'HH:mm');
        const to = moment(tTo, 'HH:mm');
        return Promise.all(this.times.map((t) => {
            if (t.isClosed && !closed) {
                const time = moment(t.time, 'HH:mm');
                if (from.isBefore(to)) {
                    t.isClosed = !(time.isBetween(from, to) ||
                        time.format('HH:mm') === from.format('HH:mm') ||
                        time.format('HH:mm') === to.format('HH:mm'));
                }
                else {
                    t.isClosed = time.isBetween(to, from);
                }
                return t;
            }
            else if (!t.isClosed && closed) {
                const time = moment(t.time, 'HH:mm');
                if (from.isBefore(to)) {
                    t.isClosed =
                        time.isBetween(from, to) ||
                            time.format('HH:mm') === from.format('HH:mm') ||
                            time.format('HH:mm') === to.format('HH:mm');
                }
                else {
                    t.isClosed = !time.isBetween(to, from);
                }
                return t;
            }
            else {
                // Time oh was alredy set
                return t;
            }
        }));
    }
    changeBoardOption() {
        if (this.reservationFormGroup.value.arrivalDate && this.reservationFormGroup.value.departureDate) {
            // Clear existing reservations and insert in correct order
            this.reservationForm.clear();
            this.checkIsSelectedTimeIntervalValid();
        }
    }
    checkIsSelectedTimeIntervalValid() {
        let reservations = this.reservationForm;
        let arrivalDate = new Date(this.reservationFormGroup.value.arrivalDate);
        arrivalDate.setHours(0, 0, 0, 0);
        let departureDate = new Date(this.reservationFormGroup.value.departureDate);
        departureDate.setHours(0, 0, 0, 0);
        const board = this.reservationFormGroup.value.board;
        if (reservations.length > 0) {
            if (arrivalDate.getTime() > this.previousArrivalDate.getTime()) {
                this.removeReservationsBefore(arrivalDate);
            }
            else if (arrivalDate.getTime() < this.previousArrivalDate.getTime()) {
                this.addElementsToReservations(arrivalDate, subDays(this.previousArrivalDate, 1), board);
            }
            if (departureDate.getTime() < this.previousDepartureDate.getTime()) {
                this.removeReservationsAfter(departureDate);
            }
            else if (departureDate.getTime() > this.previousDepartureDate.getTime()) {
                this.addElementsToReservations(addDays(this.previousDepartureDate, 1), departureDate, board);
            }
        }
        else {
            if (arrivalDate && departureDate && departureDate.getTime() > arrivalDate.getTime()) {
                this.addElementsToReservations(arrivalDate, departureDate, board);
            }
        }
        this.previousArrivalDate = new Date(this.reservationFormGroup.value.arrivalDate);
        this.previousArrivalDate.setHours(0, 0, 0, 0);
        this.previousDepartureDate = new Date(this.reservationFormGroup.value.departureDate);
        this.previousDepartureDate.setHours(0, 0, 0, 0);
    }
    /**
     * Adds reservations for **future dates only**, in correct order:
     * 1. All breakfast rows for all future dates
     * 2. All lunch rows for all future dates (if full board)
     * 3. All dinner rows for all future dates
     */
    addElementsToReservations(startDate, endDate, board) {
        let currentDate = new Date(startDate);
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const dinnerReservations = [];
        const lunchReservations = [];
        const breakfastReservations = [];
        while (currentDate.getTime() <= endDate.getTime()) {
            if (currentDate.getTime() >= today.getTime()) {
                // Only add future dates
                if (board === 'HP' || board === 'VP') {
                    dinnerReservations.push(this.addNewReservationGroup(new Date(currentDate), 'dinner'));
                }
                if (board === 'VP') {
                    lunchReservations.push(this.addNewReservationGroup(new Date(currentDate), 'lunch'));
                }
                if (board === 'FR' || board === 'HP' || board === 'VP') {
                    breakfastReservations.push(this.addNewReservationGroup(new Date(currentDate), 'breakfast'));
                }
            }
            currentDate.setDate(currentDate.getDate() + 1);
        }
        // this.insertReservationsByMealType([...dinnerReservations, ...lunchReservations, ...breakfastReservations]);
        // [...dinnerReservations, ...lunchReservations, ...breakfastReservations].forEach(res =>
        //   (this.reservationForm as FormArray).push(res)
        // );
        const existingReservationsFormControl = this.reservationForm.controls;
        const existingReservations = existingReservationsFormControl.map(control => control);
        const allReservations = [
            ...existingReservations,
            ...dinnerReservations,
            ...lunchReservations,
            ...breakfastReservations
        ];
        this.sortReservationAndFilterReservations(allReservations);
    }
    sortReservationAndFilterReservations(allReservations, allExistingReservation = []) {
        allReservations.sort((a, b) => {
            const mealOrderA = this.mealTypeOrder[a.value.mealType] || 99;
            const mealOrderB = this.mealTypeOrder[b.value.mealType] || 99;
            if (mealOrderA !== mealOrderB) {
                return mealOrderA - mealOrderB;
            }
            const dateA = new Date(a.value.date).getTime();
            const dateB = new Date(b.value.date).getTime();
            return dateA - dateB;
        });
        this.reservationForm.clear();
        allReservations.forEach((res) => {
            const mealType = res.get('mealType').value;
            const date = res.get('date').value;
            date.setHours(0, 0, 0, 0);
            const arrivalDate = new Date(this.reservationFormGroup.value.arrivalDate);
            arrivalDate.setHours(0, 0, 0, 0);
            const departureDate = new Date(this.reservationFormGroup.value.departureDate);
            departureDate.setHours(0, 0, 0, 0);
            let exempt = false;
            if (date.getTime() == arrivalDate.getTime()) {
                // console.log('firstday', date);
                exempt = mealType !== 'dinner';
            }
            if (date.getTime() == departureDate.getTime()) {
                // console.log('lastday', date);
                exempt = mealType !== 'breakfast';
            }
            res.get('exempt').setValue(exempt);
            this.reservationForm.push(res);
        });
        this.updateFirstEditableForMealType(allExistingReservation);
    }
    // To find the last index of a given mealType
    findLastMealTypeIndex(mealType) {
        const existingReservations = this.reservationForm.value;
        let lastIndex = -1;
        existingReservations.forEach((res, index) => {
            if (res.mealType === mealType) {
                lastIndex = index;
            }
        });
        return lastIndex;
    }
    /**
     * Removes reservations before a given date.
     */
    removeReservationsBefore(date) {
        const reservations = this.reservationForm;
        for (let i = reservations.length - 1; i >= 0; i--) {
            if (new Date(reservations.at(i).value.date).getTime() < date.getTime()) {
                reservations.removeAt(i);
                this.cancelExistingReservation(i);
                this.updateFirstEditableForMealType();
            }
        }
    }
    /**
     * Removes reservations after a given date.
     */
    removeReservationsAfter(date) {
        const reservations = this.reservationForm;
        for (let i = reservations.length - 1; i >= 0; i--) {
            if (new Date(reservations.at(i).value.date).getTime() > date.getTime()) {
                reservations.removeAt(i);
                this.cancelExistingReservation(i);
                this.updateFirstEditableForMealType();
            }
        }
    }
    removeReservationAtIndex(i) {
        const reservations = this.reservationForm;
        reservations.removeAt(i);
        this.cancelExistingReservation(i);
        this.updateFirstEditableForMealType();
    }
    cancelExistingReservation(i) {
        const row = this.reservationForm.value[i];
        if (row.reservationId) {
            this.canceledReservations.push(row.reservationId);
        }
    }
    updateFirstEditableForMealType(allExistingReservation = []) {
        this.breakfastCounter = 1;
        this.lunchCounter = 1;
        this.dinnerCounter = 1;
        let defaultTime = null;
        const processedMealTypes = [];
        for (let i = 0; i < this.reservationForm.value.length; i++) {
            const row = this.reservationForm.value[i];
            if (!row.exempt) {
                this.updateFormGroupValue('reservations', { resIndex: this.getMealTypeCounter(row.mealType) }, i);
                // If the mealType is not in the processed array, it's the first occurrence
                if (!processedMealTypes.includes(row.mealType)) {
                    this.updateFormGroupValue('reservations', { isEditable: true }, i);
                    processedMealTypes.push(row.mealType); // Add the mealType to the processed list
                    if (!row.time) {
                        if (row.mealType === 'breakfast') {
                            const foundTime = this.times.find(t => t.time === '08:00');
                            defaultTime = foundTime ? foundTime.time : null;
                        }
                        else if (row.mealType === 'lunch') {
                            const foundTime = this.times.find(t => t.time === '13:00');
                            defaultTime = foundTime ? foundTime.time : null;
                        }
                        else if (row.mealType === 'dinner') {
                            const foundTime = this.times.find(t => t.time === '19:00');
                            defaultTime = foundTime ? foundTime.time : null;
                        }
                        this.updateFormGroupValue('reservations', { time: defaultTime }, i);
                    }
                }
                else {
                    this.updateFormGroupValue('reservations', { isEditable: false }, i);
                }
            }
            if (allExistingReservation && allExistingReservation.length > 0) {
                if (row.reservationId) {
                    const foundReservation = allExistingReservation.find((res) => res.id === row.reservationId);
                    if (foundReservation) {
                        this.searchFreeTables(i, foundReservation.table);
                    }
                }
            }
        }
    }
    getMealTypeCounter(mealType) {
        if (mealType === 'breakfast') {
            return this.breakfastCounter++;
        }
        else if (mealType === 'lunch') {
            return this.lunchCounter++;
        }
        else if (mealType === 'dinner') {
            return this.dinnerCounter++;
        }
        return 0;
    }
    editReservation(i) {
        this.updateFormGroupValue('reservations', { isEditable: true }, i);
        this.searchFreeTables(i);
    }
    replaceGuestReservation(index) {
        const element = this.reservationForm.value[index];
        const allDataList = {
            listOfRestaurants: element.listOfRestaurants,
            listOfTimes: element.listOfTimes
        };
        const reservedFor = format(element.date, 'YYYY-MM-DD') + ' ' + element.time;
        this.reservationService
            .fetchExistingGuestReservation(reservedFor, this.reservationFormGroup.value.stayTime, element.table)
            .takeUntil(this.ngUnsubscribe)
            .subscribe((reservation) => {
            if (reservation) {
                const modalRef = this.modalService.open(ReplaceGuestReservationComponent, {
                    windowClass: 'onboarding-modal',
                    size: 'lg'
                });
                modalRef.componentInstance.reservation = reservation;
                modalRef.componentInstance.allDataList = allDataList;
                modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry) => {
                    this.reservationService.replaceGuestReservation(receivedEntry).then((response) => {
                        this.searchFreeTables(index, element.table);
                        const snackBarRef = this.snackBar.open(`${this.translate.instant('HotelReservations.Success')}`, 'Ok', {
                            duration: 3000,
                            panelClass: ['snackbar-success']
                        });
                        modalRef.close();
                    });
                });
            }
        }, err => {
            console.error('Error fetching reservation', err);
        });
    }
    onTableChanged(index) {
        const element = this.reservationForm.value[index];
        // Find if any selected table is unavailable
        const isAnyTableUnavailable = element.table.some((tableId) => {
            const foundTable = element.listOfTables.find((item) => item.table.id === tableId);
            return foundTable.isNotAvailable;
        });
        if (isAnyTableUnavailable) {
            this.updateFormGroupValue('reservations', { errorMessage: 'One or more selected tables are not free' }, index);
        }
        else {
            this.updateFormGroupValue('reservations', { errorMessage: '' }, index);
        }
    }
    replaceBoardcode(boardCode) {
        const replacements = {
            BB: 'FR',
            HB: 'HP',
            FB: 'VP'
        };
        return replacements[boardCode] || boardCode;
    }
    toggleExpandRow(row) {
        this.table.rowDetail.toggleExpandRow(row);
    }
    fetchColor(board) {
        const meal = this.mealOptions.find(option => option.value === board);
        return meal ? meal.color : 'gray';
    }
    updatePagination() {
        this.hotelReservations.sort((a, b) => {
            return new Date(a.arrivalDate).getTime() - new Date(b.arrivalDate).getTime();
        });
        this.totalPages = Math.ceil(this.hotelReservations.length / this.pageSize);
        if (this.currentPage > this.totalPages) {
            this.currentPage = this.totalPages || 1;
        }
        const start = (this.currentPage - 1) * this.pageSize;
        const end = start + this.pageSize;
        this.paginatedReservations = this.hotelReservations.slice(start, end).map((r) => (Object.assign({}, r, { expanded: false })));
        if (this.searchTerm) {
            this.paginatedReservations = this.paginatedReservations.filter((item) => {
                if (item.guestInfo) {
                    return ((item.guestInfo.firstName &&
                        item.guestInfo.firstName.toLowerCase().includes(this.searchTerm.toLowerCase())) ||
                        (item.guestInfo.name && item.guestInfo.name.toLowerCase().includes(this.searchTerm.toLowerCase())));
                }
                else {
                    return false;
                }
            });
        }
    }
    nextPage() {
        if (this.currentPage < this.totalPages) {
            this.currentPage++;
            this.updatePagination();
        }
    }
    prevPage() {
        if (this.currentPage > 1) {
            this.currentPage--;
            this.updatePagination();
        }
    }
    setHotelPaginationData() {
        this.loadingHotelRes = false;
        this.updatePagination();
    }
    fetchingStartedHotelRes() {
        this.loadingHotelRes = true;
        this.hotelReservations = [];
        this.currentPage = 1;
        this.updatePagination();
        this.initForm();
        this.reservationForm.clear();
        this.callPlaced = false;
    }
    clearSearch() {
        this.searchTerm = '';
        this.updatePagination();
    }
}
